
import { getHomepageProductList } from '@/api/myHomepagePost';
import { IMyHomepageProduct } from '@/types';
import { Vue, Component } from 'vue-property-decorator';
import Pagination from '@/components/board/pagination.vue';

@Component({
  name: 'ProductIndex',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  mounted() {
    this.getPostList();
  }

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private listQuery = {
    page: 0,
    size: 16,
    searchType: 'title',
    searchValue: '',
    orderBy: 'new',
  }

  private listTotal = 0;

  private totalPages = 0;

  private postList: IMyHomepageProduct[] = [];

  private getPostList() {
    getHomepageProductList(this.listQuery).then((res) => {
      this.listTotal = res.data.totalElements;
      this.totalPages = res.data.totalPages;
      this.postList = res.data.content;
    });
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getPostList();
  }

  private handleSearch() {
    this.listQuery.page = 0;
    this.getPostList();
  }

  private getOnclass(order: string) {
    if (this.listQuery.orderBy === order) return 'sort_on';
    return '';
  }

  private handleClickOrder(order: string) {
    this.listQuery.orderBy = order;
    this.getPostList();
  }
}
